import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { app_url, environment } from "../config";
import { CiLock } from "react-icons/ci";
import NetworkErr from "./NetworkErr";
import { AuthContext } from "../context/authContext";
import Loader from "./Loader";

const Otp = ({ username, password, loginTryFailed }) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState(10 * 60); // 10 minutes in seconds
  const [oneMinute, setOneMinute] = useState(1 * 60); // 1 minutes in seconds
  const [online, setOnline] = useState(navigator.onLine);
  const [failed, setFailed] = useState("");
  const { login } = useContext(AuthContext);
  // const token = JSON.parse(localStorage.getItem("etijwaal-agent"))?.token;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(intervalId);
          loginTryFailed();
          return prevTime;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setOneMinute((prevMinute) => {
        if (prevMinute === 0) {
          clearInterval(intervalId);
          return prevMinute;
        }
        return prevMinute - 1;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // Format the remaining time into minutes and seconds
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const loginUser = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${app_url}/api/partner/authPartner`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
          otp,
          key: environment,
        }),
      });

      // Handle response here
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message || "An error occurred");
        setFailed(errorData.message);
        return;
      }

      const user = await response.json();
      localStorage.setItem("etijwaal-agent", JSON.stringify(user?.agent));
      login(user);
      navigate("/home");
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  if (!online) {
    return <NetworkErr data={failed} />;
  }

  return (
    // <div className={`otp-input-container ${response ? "block" : "hidden"}`}>
    <div className="shadow p-4 w-full mx-4 md:mx-2">
      {/* You can style the container div as needed */}

      {/* <p className="font-bold text-sm mb-3">
        *Please Enter the OTP your customer has recieved on his email.
      </p> */}
      <div className="text-right text-sm">
        OTP will expire in: {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </div>
      <div className="flex justify-center">
        <CiLock
          style={{ borderRadius: "50%" }}
          size={80}
          className="p-3 rounded-xl shadow "
          fill="lightblue"
        />
      </div>
      <h1 className="mt-2 text-3xl">Two Factor Authentication</h1>
      <p className="text-sm">We&apos;ve sent you a verification email.</p>
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        // renderInput={(props) => (
        //   <input
        //     {...props}
        //     className="otp-input bg-gray-400 rounded-md text-center text-white font-semibold"
        //     // style={{ maxWidth: "35px", height: "35px" }}
        //   />
        // )}
        renderInput={(props) => <input {...props} />}
        inputStyle={{ width: 50, height: 50 }}
        renderSeparator={
          <span
          // className="otp-separator"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        }
        containerStyle={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2em",
        }}
      />
      <button
        onClick={() => {
          loginUser();
          console.log("function check");
        }}
        disabled={isLoading}
        // className="px-4 py-2 bg-green-600 w-1/2  rounded-full text-white mt-6"
        className="w-full mt-8 hover:opacity-70 transition-opacity duration-500  text-white py-2 sm:py-2 rounded-full sm:w-7/12"
        style={{
          backgroundColor: isLoading ? "#84A4AF" : "#568391",
          cursor: isLoading ? "not-allowed" : "pointer",
        }}
      >
        <div className="flex gap-2 justify-center">
          <span style={{ color: "#fff" }} role="status">
            Submit
          </span>
          {/* {isLoading ? "...loading" : "Register Customer"} */}
          {isLoading ? (
            <div className="flex items-center justify-center ">
              <Loader smWidth={"w-[20px]"} />
            </div>
          ) : (
            ""
          )}
        </div>
      </button>
    </div>
  );
};

export default Otp;
