import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { app_url } from "../../../config";
import toast from "react-hot-toast";
import NetworkErr from "../../../components/NetworkErr";
import { SlOptionsVertical } from "react-icons/sl";
import BuyNewBulkAvailable from "../../../components/Modals/BuyNewBulkAvailable";
import SendBulkExisting from "../../../components/Modals/SendBulkExisting";
import "../../../css/accountBalance.css";

const SingleBulk = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem("etijwaal-agent"))?.token;
  const [online, setOnline] = useState(navigator.onLine);
  const [eSimsAvailable, setAvailableEsims] = useState([]);
  const [existingPackageId, setExistingPackageId] = useState(null);
  const [active, setActive] = useState(0);
  let [dropId, setDropId] = useState("");
  const [packageName, setPackageName] = useState("");
  const [quantity, setQuantity] = useState(null);
  const [buyNewAvailable, setBuyNewAvailable] = useState(false);
  const [buyExisting, setExisting] = useState(false);
  const { id, type } = useParams();
  const navigate = useNavigate("");
  const btnRef = useRef();
  const [count, setCount] = useState(1);

  const handleClickOutside = (event) => {
    // Check if the click is outside the button and the button is not clicked
    if (
      btnRef.current &&
      !btnRef.current.contains(event.target) &&
      event.target.tagName !== "BUTTON"
    ) {
      setActive(false);
      setDropId(null);
    }
  };

  const closeNewAvailable = () => {
    setBuyNewAvailable(false);
    setCount(1);
  };
  const closeExistingModal = () => {
    setExisting(false);
    setCount(1);
  };
  const changeComponent = () => navigate("/eInventory");

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  console.log(data, "data check");

  useEffect(() => {
    const endpoint = `/api/partnerOrder/getPartnerOrderedAvailableEsimsPackagesByPartnerId?id=${id}&type=${type}`;
    setIsLoading(true);
    axios
      .get(app_url + endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data?.result.eSim);
        setAvailableEsims(res.data.result.eSim);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 401) {
          navigate("/");
        } else if (err.response?.status === 400) {
          setIsLoading(false);
          toast.error(err.message);
        } else if (err.response?.status === 404) {
          setOnline(navigator.onLine);
          setData(err.message);
        } else {
          setOnline(false);
          setData(err.message);
        }
      });
  }, [id]);

  if (!online) {
    return <NetworkErr data={data} />;
  }

  return (
    <div className="py-8">
      <div className="overflow-x-auto sm:overflow-x-visible sm:overflow-y-visible">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead
            style={{
              border: "none",
              clip: "auto",
              height: 1,
              margin: "-1px",
              overflow: "auto",
              padding: 0,
              position: "inherit",
              width: 1,
            }}
            className={`text-xs thead text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400`}
          >
            <tr style={{ marginBottom: 0 }} className="tr text-center">
              <th scope="col" className="px-6 text-xs sm:text-base py-3">
                #
              </th>
              <th scope="col" className="px-6 text-xs sm:text-base py-3">
                Package
              </th>
              <th scope="col" className="px-6 text-xs sm:text-base py-3">
                Available eSIMs
              </th>
              <th scope="col" className="px-6 text-xs sm:text-base py-3">
                Action
              </th>
            </tr>
          </thead>
          {data.map(({ packageName, packageId, isBulkAllow }, i) => (
            <tbody>
              <tr
                className={`bg-white dark:bg-gray-800 text-center dark:border-gray-700`}
              >
                <td
                  scope="row"
                  data-label={"#"}
                  width={10}
                  className="px-6 td py-2 font-medium text-xs sm:text-base text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <p className="duration-500">
                    {isLoading ? (
                      <Skeleton baseColor="#eee" width={"100%"} />
                    ) : (
                      i + 1
                    )}
                  </p>
                </td>

                <td
                  data-label={"Package Name"}
                  width={"max-content"}
                  className="px-6 td text-xs sm:text-base py-4 text-center"
                >
                  {isLoading ? (
                    <Skeleton baseColor="#eee" width={"100%"} />
                  ) : (
                    packageName
                  )}
                </td>
                <td
                  data-label={"Available eSIMs"}
                  className="px-6 py-4 td text-center"
                >
                  {isLoading ? (
                    <Skeleton baseColor="#eee" width={"100%"} />
                  ) : (
                    eSimsAvailable[i].availableEsim
                  )}
                </td>
                <td data-label={"Actions"} className="px-6 py-4 td relative">
                  {isLoading ? (
                    <Skeleton baseColor="#eee" width={"100%"} />
                  ) : (
                    <>
                      <button
                        ref={btnRef}
                        onClick={() => {
                          setActive(!active);
                          setDropId(packageId);
                        }}
                      >
                        <SlOptionsVertical size={15} />
                      </button>
                      {active && dropId === packageId ? (
                        <>
                          {isBulkAllow === 0 ? (
                            <div className="flex flex-col top-0 absolute border shadow-lg bg-white rounded-lg w-48 left-[-100px] z-20">
                              <span className="border-b py-2 hover:bg-red-50 w-full">
                                Bulk assignment of this eSIMs is not allowed.
                                Please assign individually from the eSIM
                                Inventory
                              </span>
                            </div>
                          ) : (
                            <>
                              <div className="flex flex-col top-0 absolute border shadow-lg bg-white rounded-lg w-48 left-[-100px] z-20">
                                <button
                                  onClick={() => {
                                    setBuyNewAvailable(true);
                                    setPackageName(packageName);
                                    setQuantity(
                                      eSimsAvailable[i].availableEsim
                                    );
                                    setExistingPackageId(packageId);
                                  }}
                                  className="border-b py-2 hover:bg-red-50 w-full"
                                >
                                  New Customer
                                </button>
                                <button
                                  onClick={() => {
                                    setExisting(true);
                                    setQuantity(
                                      eSimsAvailable[i].availableEsim
                                    );
                                    setExistingPackageId(packageId);
                                  }}
                                  className="border-b py-2 hover:bg-red-50 w-full"
                                >
                                  Existing Customer
                                </button>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>

      <BuyNewBulkAvailable
        open={buyNewAvailable}
        onClose={closeNewAvailable}
        packageName={packageName}
        existingPackageId={existingPackageId}
        quantity={quantity}
        changeTabOnOtpVerification={changeComponent}
        count={count}
        setCount={setCount}
      />

      <SendBulkExisting
        quantity={quantity}
        openExisting={buyExisting}
        closeExisting={closeExistingModal}
        packageId={existingPackageId}
        changeTabOnSendingEsim={changeComponent}
        count={count}
        setCount={setCount}
      />
    </div>
  );
};

export default SingleBulk;
