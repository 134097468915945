// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media screen and (max-width: 950px) {
    table {
      border: 0;
    }
  
    table caption {
      font-size: 1.3em;
    }
    
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 2.625em;
    }
    
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
    }
   
    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      color: #000;
    }
    
    table td:last-child {
      border-bottom: 0;
    }
  }

  @media (min-width: 280px) and (max-width: 400px) {
    .line {
      top: 2.9rem;
    }
  }

  @media  (min-width: 400px) and (max-width: 420px) {
    .line {
      top: 3.5rem;
    }
  }

  @media (min-width: 420px) and (max-width: 508px) {
    .line {
      top: 3.5rem;
    }
  }`, "",{"version":3,"sources":["webpack://./src/css/accountBalance.css"],"names":[],"mappings":";AACA;IACI;MACE,SAAS;IACX;;IAEA;MACE,gBAAgB;IAClB;;IAEA;MACE,YAAY;MACZ,mBAAmB;MACnB,WAAW;MACX,YAAY;MACZ,gBAAgB;MAChB,UAAU;MACV,kBAAkB;MAClB,UAAU;IACZ;;IAEA;MACE,6BAA6B;MAC7B,cAAc;MACd,sBAAsB;IACxB;;IAEA;MACE,6BAA6B;MAC7B,cAAc;MACd,eAAe;MACf,iBAAiB;IACnB;;IAEA;MACE;;;OAGC;MACD,yBAAyB;MACzB,WAAW;MACX,iBAAiB;MACjB,yBAAyB;MACzB,WAAW;IACb;;IAEA;MACE,gBAAgB;IAClB;EACF;;EAEA;IACE;MACE,WAAW;IACb;EACF;;EAEA;IACE;MACE,WAAW;IACb;EACF;;EAEA;IACE;MACE,WAAW;IACb;EACF","sourcesContent":["\n@media screen and (max-width: 950px) {\n    table {\n      border: 0;\n    }\n  \n    table caption {\n      font-size: 1.3em;\n    }\n    \n    table thead {\n      border: none;\n      clip: rect(0 0 0 0);\n      height: 1px;\n      margin: -1px;\n      overflow: hidden;\n      padding: 0;\n      position: absolute;\n      width: 1px;\n    }\n    \n    table tr {\n      border-bottom: 3px solid #ddd;\n      display: block;\n      margin-bottom: 2.625em;\n    }\n    \n    table td {\n      border-bottom: 1px solid #ddd;\n      display: block;\n      font-size: .8em;\n      text-align: right;\n    }\n   \n    table td::before {\n      /*\n      * aria-label has no advantage, it won't be read inside a table\n      content: attr(aria-label);\n      */\n      content: attr(data-label);\n      float: left;\n      font-weight: bold;\n      text-transform: uppercase;\n      color: #000;\n    }\n    \n    table td:last-child {\n      border-bottom: 0;\n    }\n  }\n\n  @media (min-width: 280px) and (max-width: 400px) {\n    .line {\n      top: 2.9rem;\n    }\n  }\n\n  @media  (min-width: 400px) and (max-width: 420px) {\n    .line {\n      top: 3.5rem;\n    }\n  }\n\n  @media (min-width: 420px) and (max-width: 508px) {\n    .line {\n      top: 3.5rem;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
